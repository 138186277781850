import { render, staticRenderFns } from "./TheOrgTokens.vue?vue&type=template&id=5a4a354e&"
import script from "./TheOrgTokens.vue?vue&type=script&lang=js&"
export * from "./TheOrgTokens.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a4a354e')) {
      api.createRecord('5a4a354e', component.options)
    } else {
      api.reload('5a4a354e', component.options)
    }
    module.hot.accept("./TheOrgTokens.vue?vue&type=template&id=5a4a354e&", function () {
      api.rerender('5a4a354e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/organization/views/TheOrgTokens.vue"
export default component.exports