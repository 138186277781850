var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between align-center w-100" },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "span",
                    { staticClass: "font-weight-bold secondary--text" },
                    [_vm._v("Organization tokens")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.fetchingOrgTokens, icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$store.dispatch(
                            "orgStore/fetchOrgTokens",
                            _vm.$route.params.oid
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("refresh")])],
                    1
                  ),
                ],
                1
              ),
              _vm.fetchingOrgTokens
                ? _c("span", { staticClass: "caption text-uppercase" }, [
                    _vm._v("fetching tokens..."),
                  ])
                : _c("span", { staticClass: "ma-0 caption" }, [
                    _vm._v(_vm._s(_vm.orgTokens.length) + " tokens found"),
                  ]),
            ]),
            _c(
              "div",
              [
                _vm.isOrgManager
                  ? _c("CreateAndEditTokenDialog", {
                      attrs: { disabled: _vm.fetchingOrgTokens },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "v-card-text",
        [
          _vm.fetchingOrgTokens
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              })
            : [
                _vm.orgTokenData.length
                  ? _c(
                      "div",
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Search for a token...",
                            autofocus: "",
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                        _c("v-data-table", {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.orgTokenData,
                            "items-per-page": 100,
                            "footer-props": {
                              itemsPerPageOptions: _vm.itemsPerPageOptions,
                            },
                            "item-key": "otknid",
                            search: _vm.search,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: `item.type`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-key")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getTokenType(item.token_type)
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.scope`,
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      _vm._s(_vm.getTokenScope(item.scope))
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.token`,
                                fn: function ({ item }) {
                                  return [
                                    item.visible
                                      ? [
                                          _vm.isJson(item.token)
                                            ? _c("div", [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass: "py-3 px-0",
                                                    staticStyle: {
                                                      "list-style-type": "none",
                                                    },
                                                  },
                                                  _vm._l(
                                                    Object.keys(
                                                      JSON.parse(item.token)
                                                    ),
                                                    function (key, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          item.visible
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "caption font-weight-bold text-uppercase secondary--text mr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          key
                                                                        ) + ":"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 secondary--text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          JSON.parse(
                                                                            item.token
                                                                          )[key]
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    _vm._g(
                                                                                      {},
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        "copy-to-clipboard",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              textToCopy:
                                                                                                JSON.parse(
                                                                                                  item.token
                                                                                                )[
                                                                                                  key
                                                                                                ],
                                                                                              buttonClass:
                                                                                                "caption font-weight-bold",
                                                                                              buttonColor:
                                                                                                "secondary",
                                                                                              buttonName:
                                                                                                "copy to clipboard",
                                                                                              iconButton: true,
                                                                                              isSmall: true,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Copy to clipboard"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ])
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "subtitle-2 secondary--text",
                                                },
                                                [_vm._v(_vm._s(item.token))]
                                              ),
                                        ]
                                      : _c("span", [_vm._v("••••••••")]),
                                  ]
                                },
                              },
                              {
                                key: `item.actions`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        !item.visible
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    item.visible = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("visibility_off"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.visible
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    item.visible = false
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("visibility"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isOrgManager
                                          ? _c("CreateAndEditTokenDialog", {
                                              attrs: {
                                                dialogAction: "editToken",
                                                isIconDialog: true,
                                                tokenData: item,
                                                activatorIcon: "edit",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.isOrgManager
                                          ? _c("SSHDeleteDialog", {
                                              attrs: {
                                                token: item.token,
                                                dialogTitle: "Token Delete",
                                                apiURL: `/org_tokens/${item.otknid}`,
                                                fetchTokensDispatch: () => {
                                                  _vm.$store.dispatch(
                                                    "orgStore/fetchOrgTokens",
                                                    _vm.$route.params.oid
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _c(
                      "v-banner",
                      { attrs: { "two-line": "" } },
                      [
                        _c(
                          "v-avatar",
                          {
                            attrs: { slot: "icon", color: "white", size: "60" },
                            slot: "icon",
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "x-large": "",
                                  icon: "info",
                                  color: "info",
                                },
                              },
                              [_vm._v("info")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-bold secondary--text mb-1",
                            },
                            [_vm._v("No tokens found")]
                          ),
                          _c("span", [
                            _vm._v(
                              " Currently you don't have any token in this organization. "
                            ),
                            _vm.isOrgManager
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(" You can add a new token via the "),
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("add"),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bold text-uppercase primart--text",
                                      },
                                      [_vm._v("CREATE NEW TOKEN")]
                                    ),
                                    _vm._v(" button above. "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ],
                      1
                    ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }